<template>
	<v-row justify="center">
		<v-dialog v-model="dialog" persistent max-width="900px">
			<v-card class="px-8 py-4 mb-0">
				<h1 class="display-1 mb-2 text-center">Picture</h1>
				<p class="heading mb-1">Select Picture</p>
				<FileUploader
					:initFiles="banner ? [banner] : []"
					@errorOccured="dropzoneError"
					:purpose="`banner`"
					:maxFiles="1"
					@changed="fileUploaderChanged"
				/>
				<span class="error-msg">{{ errors.banner }}</span>
				<p class="heading mb-1">Alt Text</p>
				<v-text-field
					flat
					dense
					placeholder="Enter the Alt text for the Picture."
					outlined
					:error-messages="errors.altText"
					v-model="altText"
				></v-text-field>
				<p class="heading mb-1">Caption</p>
				<v-text-field
					solo
					flat
					dense
					placeholder="Enter the Caption for the Picture."
					outlined
					v-model="caption"
					:error-messages="errors.caption"
				></v-text-field>
				<v-card-actions class="btn-action">
					<v-btn @click="clear()" depressed class="save">Close</v-btn>
					<v-btn @click="saveContent()" depressed class="save"
						>Save</v-btn
					></v-card-actions
				>
			</v-card>
		</v-dialog>
	</v-row>
</template>

<script>
import FileUploader from '@/components/FileUploader'
export default {
	components: {
		FileUploader,
	},
	props: {
		post: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		return {
			dialog: true,
			banner: null,
			caption: null,
			bannerId: null,
			altText: null,
			purpose: '',
			errors: {
				banner: '',
				altText: '',
				caption: '',
			},
		}
	},
	created() {
		if (this.post && Object.keys(this.post).length > 0) {
			this.banner = this.post.banner ? this.post.banner : null
			this.caption = this.post.caption
			this.bannerId =
				this.post.banner && this.post.banner.media
					? this.post.banner.media
					: null
			this.altText = this.post.altText
		}
	},
	watch: {
		banner() {
			return (this.errors.banner = '')
		},
		altText() {
			return (this.errors.altText = '')
		},
		caption() {
			return (this.errors.caption = '')
		},
	},
	methods: {
		clear() {
			this.$emit('close-dialog')
		},
		fileUploaderChanged(val) {
			if (val.length != 0) {
				const data = val[0]
				this.bannerId = data.id
				this.purpose = 'banner'
				this.banner = {
					media: this.bannerId,
					id: this.bannerId,
					publicURL: data.publicURL,
				}
			} else {
				this.bannerId = null
				this.banner = null
			}
		},
		dropzoneError(val) {
			console.log(val)
		},
		saveContent() {
			let isValid = true
			if (this.banner === null) {
				this.errors.banner = 'Please Upload Picture'
				isValid = false
			}
			if (this.altText === null) {
				this.errors.altText =
					'Please Enter alt text for this image which contain atleast one local Keyword'
				isValid = false
			}
			if (this.caption === null) {
				this.errors.caption =
					'Please Enter Caption text for this image which contain atleast one local Keyword'
				isValid = false
			}
			if (isValid) {
				let picture = {
					banner: this.banner,
					// bannerId: this.bannerId,
					caption: this.caption,
					altText: this.altText,
				}
				this.$emit('on-post-update', picture)
			}
		},
	},
}
</script>

<style scoped>
.v-tabs {
	width: 80%;
	margin: 6.2rem auto;
}
.btn-action {
	display: flex;
	justify-content: flex-end;
}
.save {
	background-color: var(--blue) !important;
	color: white;
	text-transform: capitalize;
	padding: 10px 8px;
}
</style>