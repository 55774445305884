<template>
	<div>
		<v-card>
			<div class="d-flex align-center justify-space-between px-4">
				<v-card-title>Pictures</v-card-title>
				<v-btn
					color="var(--blue)"
					dark
					class="px-4 text-capitalize"
					@click="createPost()"
				>
					Upload Picture
				</v-btn>
			</div>

			<v-divider></v-divider>

			<v-card height="400px" class="scroll mb-0">
				<v-row class="mx-0 d-flex" v-if="posts.length > 0">
					<v-col
						cols="3"
						v-for="(post, index) in posts"
						:key="index"
						class="d-flex"
					>
						<v-card class="mb-0">
							<div
								class="image-container"
								v-if="post.banner !== null"
							>
								<v-img :src="post.banner.publicURL"></v-img>
							</div>
							<div
								class="d-flex flex-row justify-space-between align-center"
							>
								<p class="body-1 ma-0">
									{{ displayCaption(post.caption) }}
								</p>

								<v-menu class="actions-dropdown">
									<template #activator="{ on: onMenu }">
										<v-tooltip bottom>
											<template
												#activator="{ on: onTooltip }"
											>
												<v-btn
													icon
													v-on="{
														...onMenu,
														...onTooltip,
													}"
													class="actions"
													><span
														class="mdi mdi-dots-vertical"
													></span
												></v-btn>
											</template>

											<span>Other Actions</span>
										</v-tooltip>
									</template>

									<v-list>
										<v-list-item>
											<v-list-item-title
												class="options"
												@click="removePost(index)"
												>Delete</v-list-item-title
											>
										</v-list-item>
										<v-list-item>
											<v-list-item-title
												class="options"
												@click="editPost(post, index)"
												>Edit</v-list-item-title
											>
										</v-list-item>
									</v-list>
								</v-menu>
							</div>
						</v-card>
					</v-col>
				</v-row>
			</v-card>
		</v-card>
		<PictureCreateIndividualDialog
			v-if="showPostDialog"
			@on-post-update="updatePost($event)"
			@close-dialog="closeDialog($event)"
			:post="selectedPost"
		/>
	</div>
</template>

<script>
import PictureCreateIndividualDialog from './PictureCreateIndividualDialog'
export default {
	props: {
		pictureIndividualPosts: {
			type: Array,
			required: true,
			default: () => [],
		},
	},
	data() {
		return {
			posts: [],
			selectedPost: null,
			index: null,
			showPostDialog: false,
		}
	},
	created() {
		this.posts = this.pictureIndividualPosts
		// console.log(3)
	},
	components: {
		PictureCreateIndividualDialog,
	},
	methods: {
		displayCaption(caption){
			if(caption.length>27){
				return `${caption.slice(0,27)}...`
			}else{
				return caption
			}

		},
		editPost(post, ind) {
			this.showPostDialog = !this.showPostDialog
			this.selectedPost = post
			this.index = ind
		},
		createPost() {
			this.showPostDialog = !this.showPostDialog
		},
		removePost(index) {
			let posts = this.posts
			posts.splice(index, 1)
			this.posts = posts
			this.savePost()
		},
		closeDialog(emittedObj) {
			this.showPostDialog = !this.showPostDialog
			this.$emit('close-dialog', emittedObj)
		},
		updatePost(emittedObj) {
			if (this.index != null && this.selectedPost) {
				//Existing
				this.posts[this.index] = emittedObj
			} else {
				//New
				this.posts.push(emittedObj)
			}
			this.savePost()
			this.selectedPost = null
			this.index = null
			this.showPostDialog = false
			//request
		},
		savePost() {
			const projectId = this.$store.state.app.projectId
			const postsData = this.posts.map((post) => {
				post.banner =
					typeof post.banner === 'object' && post.banner !== null
						? post.banner.media
						: ''
				return post
			})
			let payload = {
				pictures: {
					individualPosts: postsData,
				},
			}
			this.axios({
				method: 'patch',
				url: `/${projectId}/pages/gallery`,
				data: payload,
			}).then((res) => {
				this.posts = res.data.data.meta.pictures.individualPosts
			}).catch(error=>{
					const { data } = error.response
					if (data.statusCode == 400) {
						this.isSaving=false
						this.$snackbar.notify({
							message:data.message,
							color: 'black',
						})
					}
			})
		},
	},
}
</script>

<style scoped>
.scroll {
	overflow-y: scroll;
}
.image-container {
	height: 100%;
	max-height: 250px;
	overflow: hidden;
}
</style>