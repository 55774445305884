<template>
	<div>
		<v-card>
			<div class="d-flex align-center justify-space-between px-4">
				<v-card-title>Albums</v-card-title>
				<v-btn
					color="var(--blue)"
					dark
					class="px-4 text-capitalize"
					@click="addAlbum()"
				>
					Create Album
				</v-btn>
			</div>
			<v-divider></v-divider>
			<v-card height="400px" class="scroll mb-0">
				<v-row class="mx-0 d-flex" v-if="albums.length > 0">
					<v-col
						cols="4"
						v-for="(album, index) in albums"
						:key="index"
						class="d-flex"
					>
						<v-card width="100%" class="mb-0">
							<v-card-title class="grey lighten-4">
								<span class="text-h6">{{
									displayTitle(album.title)
								}}</span>

								<v-spacer></v-spacer>

								<v-menu bottom right>
									<template v-slot:activator="{ on, attrs }">
										<v-btn icon v-bind="attrs" v-on="on">
											<v-icon dark
												>mdi-dots-vertical</v-icon
											>
										</v-btn>
									</template>

									<v-list>
										<v-list-item>
											<v-list-item-title
												class="options"
												@click="removeAlbum(index)"
												>Delete</v-list-item-title
											>
										</v-list-item>
										<v-list-item>
											<v-list-item-title
												class="options"
												@click="editAlbum(album, index)"
												>Edit</v-list-item-title
											>
										</v-list-item>
									</v-list>
								</v-menu>
							</v-card-title>

							<v-card-text class="body-1 black--text py-2">{{
								displayDescription(album.description)
							}}</v-card-text>
						</v-card>
					</v-col>
				</v-row>
			</v-card>
		</v-card>
		<PictureCreateAlbumDrawer
			v-if="showAlbumDrawer"
			@on-album-update="updateAlbum($event)"
			@close-drawer="closeDrawer($event)"
			:album="selectedAlbum"
		/>
	</div>
</template>

<script>
import PictureCreateAlbumDrawer from './PictureCreateAlbumDrawer'
export default {
	props: {
		pictureAlbums: {
			type: Array,
			required: true,
			default: () => [],
		},
	},
	data() {
		return {
			albums: [],
			selectedAlbum: null,
			index: null,
			showAlbumDrawer: false,
		}
	},
	created() {
		this.albums = this.pictureAlbums
	},
	components: {
		PictureCreateAlbumDrawer,
	},
	methods: {
		displayTitle(title) {
			if (title.length > 20) {
				return `${title.slice(0, 20)}...`
			} else {
				return title
			}
		},
		displayDescription(description) {
			if (description.length > 20) {
				return `${description.slice(0, 20)}...`
			} else {
				return description
			}
		},
		editAlbum(album, ind) {
			this.showAlbumDrawer = !this.showAlbumDrawer
			this.selectedAlbum = album
			this.index = ind
		},
		addAlbum() {
			this.showAlbumDrawer = !this.showAlbumDrawer
		},
		closeDrawer(emittedObj) {
			this.showAlbumDrawer = !this.showAlbumDrawer
			this.$emit('close-drawer', emittedObj)
		},
		removeAlbum(index) {
			let albums = this.albums
			albums.splice(index, 1)
			this.albums = albums
			this.saveAlbum()
		},
		updateAlbum(emittedObj) {
			if (this.index != null && this.selectedAlbum) {
				//Existing
				this.albums[this.index] = emittedObj
			} else {
				//New
				this.albums.push(emittedObj)
			}
			this.saveAlbum()
			this.selectedAlbum = null
			this.index = null
			this.showAlbumDrawer = false
		},
		saveAlbum() {
			const projectId = this.$store.state.app.projectId
			const postsData = this.albums.map((post) => {
				post.posts = post.posts.map((albumPost) => {
					albumPost.banner =
						typeof albumPost.banner === 'object' &&
						albumPost.banner !== null
							? albumPost.banner.media
							: ''
					return albumPost
				})
				return post
			})
			let payload = {
				pictures: {
					albums: postsData,
				},
			}
			this.axios({
				method: 'patch',
				url: `/${projectId}/pages/gallery`,
				data: payload,
			})
				.then((res) => {
					this.albums = res.data.data.meta.pictures.albums
				})
				.catch((error) => {
					const { data } = error.response
					if (data.statusCode == 400) {
						this.isSaving = false
						this.$snackbar.notify({
							message: data.message,
							color: 'black',
						})
					}
				})
		},
	},
}
</script>

<style scoped>
.scroll {
	overflow-y: scroll;
}
</style>