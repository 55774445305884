<template>
	<div>
		<PictureIndividualPost
			:pictureIndividualPosts="pictures.individualPosts"
		/>
		<PictureAlbums :pictureAlbums="pictures.albums" />
	</div>
</template>

<script>
import PictureAlbums from './PictureAlbums'
import PictureIndividualPost from './PictureIndividualPost'
export default {
	props: {
		pictures: {
			type: Object,
			required: true,
		},
	},
	components: {
		PictureAlbums,
		PictureIndividualPost,
	},
}
</script>